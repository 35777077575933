.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cs-parallax {
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.favico {
  height: 30px !important;
  margin-left: 0.75em !important;
}

.navbar {
  height: 60px !important;
}

.cs-container-block {
  min-height: 50em;
}

h1 {
  font-size: 4em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 3em;
  }
}

@media only screen and (min-width: 576px) {
	.nav-item {
		margin: 0 0.5em;
	}

	.favico {
		margin-left: 1.25em !important;
		margin-right: 1.5em;
	  }
}

@media only screen and (min-width: 992px) {
  .cs-container-min {
    width: 50em !important;
  }
}