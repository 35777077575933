.cs-container {
    min-height: 20em;
    background-image: url("../../../imgs/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

.cs-container h1, .cs-container p {
    text-align: center;
    color: white;
}

.cs-container h1 {
    font-weight: bold;
    color: #fff;
}

.cs-container p {
    font-size: 1.25em;
}

.cs-container button {
    border-radius: .5em;
}